











































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class IntegrationConfigMapping extends Vue {
  @Prop({ required: true })
  config: any;

  @Prop({ required: true })
  target: any;

  @Prop({ default: true })
  bulkChange: boolean;

  changeMapping(event: Event, index: string): void {
    const value = (event.target! as HTMLSelectElement).value || null;
    this.$emit('update-mapping', { index, value });
  }

  changeAll(event: Event): void {
    const targetOption = (event.target! as HTMLSelectElement).value || null;

    this.$emit('update-all', targetOption);
  }
}
