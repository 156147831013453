var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"is-active",class:{ modal: !_vm.noModal }},[(!_vm.noModal)?_c('div',{staticClass:"modal-background",on:{"click":_vm.close}}):_vm._e(),_c('div',{class:{ 'modal-content': !_vm.noModal, container: !_vm.noModal }},[_c('div',{staticClass:"card"},[(!_vm.noHeader)?_c('header',{staticClass:"card-header"},[_c('div',{directives:[{name:"t",rawName:"v-t",value:('settings.integrations.settings'),expression:"'settings.integrations.settings'"}],staticClass:"card-header-title"})]):_vm._e(),_c('div',{staticClass:"card-content"},[(_vm.loading)?_c('div',{staticClass:"loader"}):_vm._e(),(
            _vm.connecting &&
            _vm.$i18n.te(
              'settings.integrations.help.' +
                _vm.integration.name +
                '.connecting'
            )
          )?_c('div',{directives:[{name:"t",rawName:"v-t",value:(
            'settings.integrations.help.' + _vm.integration.name + '.connecting'
          ),expression:"\n            'settings.integrations.help.' + integration.name + '.connecting'\n          "}],staticClass:"connecting-help"}):_vm._e(),(!_vm.loading && _vm.integration && _vm.integration.config)?_c('form',{staticClass:"form is-clearfix",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_vm._l((_vm.currentOptions),function(cfg,name){return _c('div',{key:name,staticClass:"field"},[_c('label',{staticClass:"label",domProps:{"textContent":_vm._s(_vm.translate(name))}}),(
                _vm.$i18n.te(
                  'settings.integrations.help.' +
                    _vm.integration.name +
                    '.' +
                    name
                )
              )?_c('div',{directives:[{name:"t",rawName:"v-t",value:(
                'settings.integrations.help.' + _vm.integration.name + '.' + name
              ),expression:"\n                'settings.integrations.help.' + integration.name + '.' + name\n              "}],staticClass:"option-help"}):_vm._e(),(cfg.type === 'string')?_c('div',{staticClass:"control"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.props[name]),expression:"props[name]",modifiers:{"trim":true}}],staticClass:"input",attrs:{"required":"","placeholder":_vm.translate(name)},domProps:{"value":(_vm.props[name])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.props, name, $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]):(cfg.type === 'message')?_c('div',{staticClass:"control"},[_c('i18n',{attrs:{"path":("settings.integrations.messages." + (_vm.integration.name) + "." + name),"tag":"p"},scopedSlots:_vm._u([{key:name,fn:function(){return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.props.option[name])}})]},proxy:true},{key:"info",fn:function(){return [_c('a',{attrs:{"target":"_blank","href":_vm.$t(
                        ("settings.integrations.messages." + (_vm.integration.name) + ".info")
                      )}},[_vm._v("(info)")])]},proxy:true},{key:"br",fn:function(){return [_c('br')]},proxy:true}],null,true)})],1):(cfg.type === 'int')?_c('div',{staticClass:"control"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.props[name]),expression:"props[name]"}],staticClass:"input",attrs:{"required":"","type":"number","placeholder":_vm.translate(name)},domProps:{"value":(_vm.props[name])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.props, name, $event.target.value)}}})]):(cfg.type === 'enum')?_c('div',{staticClass:"control"},[_c('div',{staticClass:"select"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.props[name]),expression:"props[name]"}],on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.props, name, $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){cfg.autosave && _vm.$emit('save-and-continue', _vm.props)}]}},_vm._l((cfg.options),function(v,k){return _c('option',{key:k,attrs:{"id":k},domProps:{"value":k,"textContent":_vm._s(v)}})}),0)])]):(cfg.type === 'mapping')?_c('div',{staticClass:"mapping-list"},[(
                  _vm.$i18n.te(
                    'settings.integrations.help.' +
                      _vm.integration.name +
                      '.mapping'
                  )
                )?_c('div',{directives:[{name:"t",rawName:"v-t",value:(
                  'settings.integrations.help.' +
                  _vm.integration.name +
                  '.mapping'
                ),expression:"\n                  'settings.integrations.help.' +\n                  integration.name +\n                  '.mapping'\n                "}],staticClass:"mapping-help"}):_vm._e(),_c('integration-config-mapping',{attrs:{"config":cfg,"target":_vm.props[name]},on:{"update-mapping":function($event){return _vm.updateMapping([name], $event)},"update-all":function($event){return _vm.updateAll(cfg, [name], $event)}}})],1):_vm._e()])}),_vm._l((_vm.currentConfigOptions),function(cfg,name){return _c('div',{key:name,staticClass:"field"},[_c('label',{staticClass:"label",domProps:{"textContent":_vm._s(_vm.translate(name))}}),(
                _vm.$i18n.te(
                  'settings.integrations.help.' +
                    _vm.integration.name +
                    '.' +
                    name
                )
              )?_c('div',{directives:[{name:"t",rawName:"v-t",value:(
                'settings.integrations.help.' + _vm.integration.name + '.' + name
              ),expression:"\n                'settings.integrations.help.' + integration.name + '.' + name\n              "}],staticClass:"option-help"}):_vm._e(),(cfg.type === 'string')?_c('div',{staticClass:"control"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.props.option[name]),expression:"props.option[name]",modifiers:{"trim":true}}],staticClass:"input",attrs:{"placeholder":_vm.translate(name)},domProps:{"value":(_vm.props.option[name])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.props.option, name, $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]):(cfg.type === 'int')?_c('div',{staticClass:"control"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.props.option[name]),expression:"props.option[name]"}],staticClass:"input",attrs:{"type":"number","placeholder":_vm.translate(name)},domProps:{"value":(_vm.props.option[name])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.props.option, name, $event.target.value)}}})]):(cfg.type === 'enum')?_c('div',{staticClass:"control"},[_c('div',{staticClass:"select"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.props.option[name]),expression:"props.option[name]"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.props.option, name, $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((cfg.options),function(v,k){return _c('option',{key:k,attrs:{"id":k},domProps:{"value":k,"textContent":_vm._s(v)}})}),0)])]):(cfg.type === 'mapping')?_c('div',{staticClass:"mapping-list"},[(
                  _vm.$i18n.te(
                    'settings.integrations.help.' +
                      _vm.integration.name +
                      '.mapping'
                  )
                )?_c('div',{directives:[{name:"t",rawName:"v-t",value:(
                  'settings.integrations.help.' +
                  _vm.integration.name +
                  '.mapping'
                ),expression:"\n                  'settings.integrations.help.' +\n                  integration.name +\n                  '.mapping'\n                "}],staticClass:"mapping-help"}):_vm._e(),_c('integration-config-mapping',{attrs:{"config":cfg,"target":_vm.props.option[name]},on:{"update-mapping":function($event){return _vm.updateMapping(['option', name], $event)},"update-all":function($event){return _vm.updateAll(cfg, ['option', name], $event)}}})],1):_vm._e()])}),_c('div',{staticClass:"buttons is-pulled-right"},[(!_vm.noCancel)?_c('button',{directives:[{name:"t",rawName:"v-t",value:('_.cancel'),expression:"'_.cancel'"}],staticClass:"button is-danger",on:{"click":function($event){$event.preventDefault();return _vm.close.apply(null, arguments)}}}):_vm._e(),(_vm.connecting)?_c('input',{staticClass:"button is-success",attrs:{"type":"submit"},domProps:{"value":_vm.$i18n.t('settings.integrations.connect')}}):_c('button',{directives:[{name:"t",rawName:"v-t",value:('_.save'),expression:"'_.save'"}],staticClass:"button is-success",class:{ 'is-loading': _vm.isSaving },attrs:{"type":"submit","disabled":_vm.isSaving}})])],2):_vm._e()])])]),(!_vm.noModal)?_c('button',{staticClass:"modal-close is-large",attrs:{"aria-label":"close"},on:{"click":_vm.close}}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }