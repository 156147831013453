






















































































































































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Integration } from '@/models';

import IntegrationConfigMapping from './IntegrationConfigMapping.vue';
import { unwrapError } from '@/lib/helpers';
import { pickBy, get, set } from 'lodash-es';

@Component({
  components: {
    IntegrationConfigMapping,
  },
})
export default class IntegrationConfigModal extends Vue {
  @Prop({ required: true })
  integration: Integration;

  @Prop({ default: false })
  connecting: boolean;

  @Prop({ default: false, type: Boolean })
  isSaving: boolean;

  @Prop({ default: false, type: Boolean })
  noModal: boolean;

  @Prop({ default: false, type: Boolean })
  noCancel: boolean;

  @Prop({ default: false, type: Boolean })
  noHydrate: boolean;

  @Prop({ default: false, type: Boolean })
  noHeader: boolean;

  loading: boolean = true;
  props: Record<string, any> | null = null;

  async mounted(): Promise<void> {
    try {
      if (!this.noHydrate) {
        await this.integration.hydrateConfiguration();
      }
      this.props = {
        option: Object.assign({}, this.integration.option),
        office: this.integration.office,
      };
      this.loading = false;
    } catch (e) {
      this.$toaster.error('Could not fetch integration config', unwrapError(e));
    }
  }

  async onSubmit(): Promise<void> {
    if (this.connecting) {
      this.$emit('connect', this.props);
    } else {
      this.$emit('save', this.props);
    }
  }

  close(): void {
    this.$emit('close');
  }

  updateMapping(
    path: string[],
    event: { value: string | null; index: string },
  ): void {
    if (this.props === null) {
      return;
    }
    if (Array.isArray(get(this.props, path, null))) {
      set(this.props, path, {});
    }
    set(this.props, [...path, event.index], event.value);
  }

  updateAll(config: any, path: string[], value: string): void {
    if (this.props === null) {
      return;
    }
    if (Array.isArray(get(this.props, path, null))) {
      set(this.props, path, {});
    }
    for (const key of Object.keys(config.names)) {
      set(this.props, [...path, key], value);
    }
  }

  translate(prop: string): string {
    const i18nKey = `settings.integrations.fields.${this.integration.name}.${prop}`;

    if (this.$i18n.te(i18nKey)) {
      return this.$i18n.tc(i18nKey).toString();
    }

    return prop;
  }
  // TODO(joost 11-10-2021): I don't fully get the type juggling that we're doing here
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get currentOptions() {
    if (!this.integration || !this.integration.config) return [];
    return pickBy(
      this.integration.config,
      (cfg, name) => name !== 'option' && (!this.connecting || cfg.signup),
    );
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get currentConfigOptions() {
    if (!this.integration || !this.integration.config.option) return [];
    return pickBy(
      this.integration.config.option,
      (cfg) => !this.connecting || cfg.signup,
    );
  }
}
