var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"company-list"},[_c('div',{staticClass:"fl-row tni-row--header"},[_vm._l((_vm.columns),function(column,idx){
var _obj;
return _c('div',{directives:[{name:"t",rawName:"v-t",value:('customer.' + column),expression:"'customer.' + column"}],key:idx,class:( _obj = {
        'fl-zero': true
      }, _obj['fl-grow-' + _vm.columnDefinitions[column].width] = true, _obj['is-hidden-mobile'] =  _vm.columnDefinitions[column].isHiddenMobile, _obj )})}),_c('div',{staticClass:"fl-zero fl-grow-3"},[_vm._v("Actions")])],2),_vm._l((_vm.companies),function(company){return _c('div',{key:company.id,staticClass:"tni-row fl-row"},[_vm._l((_vm.columns),function(column,idx){
      var _obj;
return _c('div',{key:idx,class:( _obj = {
        'fl-zero': true
      }, _obj['fl-grow-' + _vm.columnDefinitions[column].width] = true, _obj['is-hidden-mobile'] =  _vm.columnDefinitions[column].isHiddenMobile, _obj ),domProps:{"textContent":_vm._s(company[column])}})}),_c('div',{staticClass:"fl-one fl-grow-1"},[_c('button',{staticClass:"button",on:{"click":function($event){$event.preventDefault();return _vm.$emit('edit', company)}}},[_vm._v(" Edit ")]),_c('button',{staticClass:"button is-primary ml-3",on:{"click":function($event){$event.preventDefault();return _vm.login(company.id)}}},[_vm._v(" Login ")])])],2)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }