










































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { Company } from '@/models';
import { AdminService, AuthService } from '@/lib/services';
import { unwrapError } from '@/lib/helpers';
import { namespace } from 'vuex-class';

const sAuth = namespace('auth');

@Component
export default class CompanyLoginList extends Vue {
  @Prop({ required: true })
  companies: Company[];

  @Prop({
    type: Array,
    default: () => ['name', 'package', 'nextInvoiceNumber'],
  })
  columns: string[];

  @sAuth.Getter('isAdmin') isAdmin: boolean;

  columnDefinitions = {
    id: { width: 1, isHiddenMobile: false },
    name: { width: 4, isHiddenMobile: false },
    package: { width: 2, isHiddenMobile: true },
    nextInvoiceNumber: { width: 2, isHiddenMobile: true },
    accountant: { width: 2, isHiddenMobile: false },
    status: { width: 2, isHiddenMobile: true },
  };

  async login(companyId: number): Promise<void> {
    try {
      if (this.isAdmin) {
        await AdminService.login(companyId, 'company');
      } else {
        await AuthService.switchCompany(companyId);
      }
      await this.$router.push('/');
    } catch (e) {
      this.$toaster.error(unwrapError(e));
    }
  }
}
